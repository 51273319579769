<template>
    <div
        style="width: 100%; height: 90%;"
        class="d-flex align-center justify-center"
    >
        <div>
            <v-text-field
                v-model="search"
                hide-details
                label="Search By Code"
                prepend-inner-icon="mdi-barcode"
                outlined
                clearable
                filled
                :loading="loading"
            />
            <v-row class="mt-3 mx-0">
                <v-btn-toggle
                    v-model="searchType"
                    mandatory
                    borderless
                    style="height: 36px;"
                    class="mb-2"
                    @change="search = ''"
                >
                    <v-btn
                        value="WO"
                        style="font-size: 14px; height: 36px;"
                        class="px-4 py-1"
                    >
                        {{
                            this.$vuetify.breakpoint.smAndDown
                                ? 'WO'
                                : 'Work Order'
                        }}
                    </v-btn>
                    <v-btn
                        value="BOM"
                        style="font-size: 14px; height: 36px;"
                        class="px-4 py-1"
                    >
                        {{
                            this.$vuetify.breakpoint.smAndDown
                                ? 'BOM'
                                : 'Bill Of Material'
                        }}
                    </v-btn>
                </v-btn-toggle>
                <v-spacer style="min-width: 80px;" />
                <v-btn
                    color="primary"
                    @click="searchElement"
                    :loading="loading"
                    :disabled="search == null || search.length == 0"
                >
                    <v-icon>mdi-database-search</v-icon>
                    SEARCH
                </v-btn>
            </v-row>
        </div>

        <v-alert
            v-if="activateAlert"
            type="success"
            color="primary"
            style="position: absolute; right: 0px; bottom: 0px"
        >
            <v-row no-gutters>
                <v-col cols="11">
                    <p class="my-0">{{ alertMessage }}</p>
                </v-col>
                <v-col cols="1">
                    <v-btn
                        small
                        icon
                        class="mt-n1"
                        @click="activateAlert = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-alert>

        <!--PROCESS WO-->
        <v-dialog
            v-model="processWorkOrder"
            :retain-focus="false"
            persistent
            fullscreen
        >
            <OperatorProcessWorkOrder
                v-if="processWorkOrder"
                :order="selectedOrder"
                @close="closeProcessWorkOrder"
            />
        </v-dialog>

        <!--PROCESS BOM-->
        <v-dialog
            v-model="processBOM"
            :retain-focus="false"
            persistent
            fullscreen
        >
            <OperatorProcessBOM
                v-if="processBOM"
                :bom="selectedBOM"
                :process="'Bill Of Material'"
                @close="closeProcessBOM"
                @sendToDeliver="processBOM = false"
                @replaceItems="replaceItems"
                @partialsAlert="partialsAlert"
            />
        </v-dialog>
    </div>
</template>

<script>
import _ from 'lodash'
import { firestore } from '@/services/firebase'
import API from '@/services/api'
import { mapMutations, mapGetters, mapActions } from 'vuex'

export default {
    name: 'OperatorSearch',
    props: {
        projectId: {
            type: String,
            default: () => undefined,
        },
        project: {
            type: Object,
            deafult: () => ({}),
        },
    },
    components: {
        OperatorProcessWorkOrder: () =>
            import('@/components/WorkOrders/OperatorProcessWorkOrder.vue'),
        OperatorProcessBOM: () =>
            import('@/components/WorkOrders/OperatorProcessBOM.vue'),
    },
    data: () => ({
        selectedOrder: undefined,
        processWorkOrder: false,
        loading: false,
        projects: [],
        activateAlert: false,
        alertMessage: undefined,
        search: null,
        listener: undefined,
        companyId: JSON.parse(localStorage.getItem('company')),
        processToShow: undefined,
        showProcesses: false,
        settingProcesses: undefined,
        searchType: null,
        users: [],
        processBOM: false,
        selectedBOM: undefined,
        qualityProcess: {
            id: 'quality',
            name: 'Quality',
        },
        readyForPackingProcess: {
            id: 'readyForPacking',
            name: 'Ready for packing',
        },
        packingProcess: {
            id: 'packing',
            name: 'Packing',
        },
    }),
    async mounted() {
        try {
            this.loading = true
            const {
                data: { user },
            } = await API.getMyInfo()
            this.user = user

            if (user.permissions.includes('watchProcesses')) {
                this.showProcesses = true
            } else if (user.process) {
                this.processToShow = user.process
                this.showProcesses = true
            }

            const {
                data: { settings },
            } = await API.getSettings()
            this.settingProcesses = settings.find(s => s.name == 'Processes')

            const {
                data: { users },
            } = await API.getLiteUsers()
            this.users = users

            this.projects = await API.getLiteProjects({ all: true })
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    beforeDestroy() {
        if (this.listener) {
            this.listener()
            this.listener = null
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        ...mapActions(['setNotificationResource']),
        ...mapGetters(['getNotificationResource']),
        async searchElement() {
            try {
                this.loading = true
                let response = undefined
                if (this.searchType == 'WO') {
                    response = await API.findWOByCode(this.search.toUpperCase())
                    if (response) {
                        this.trackWO(response)
                    }
                } else if (this.searchType == 'BOM') {
                    response = await API.findBOMByCode(
                        this.search.toUpperCase()
                    )
                    if (response) {
                        this.trackBOM(response)
                    }
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        openProcessWorkOrder() {
            if (!this.processWorkOrder) this.processWorkOrder = true
        },
        closeProcessWorkOrder() {
            this.listener()
            this.listener = null
            this.selectedOrder = {}
            this.processWorkOrder = false
        },
        setProcessesInfo(wo) {
            wo.processesInfo = []

            let qualityProcess = {
                process: {
                    id: this.qualityProcess.id,
                    name: this.qualityProcess.name,
                },
                qty: 0,
                received: 0,
                delivered: 0,
                produced: 0,
                available: 0,
            }

            let packingProcess = {
                process: {
                    id: this.packingProcess.id,
                    name: this.packingProcess.name,
                },
                qty: 0,
                received: 0,
                delivered: 0,
                produced: 0,
                available: 0,
            }

            let showDefaultProcesses = false
            if (wo.items) {
                wo.items.forEach(item => {
                    if (item.processes) {
                        if (this.processToShow) {
                            if (!showDefaultProcesses) {
                                const processIndex = item.processes.findIndex(
                                    p => p == this.processToShow.id
                                )

                                showDefaultProcesses =
                                    processIndex == item.processes.length - 1
                            }
                        }
                        item.processesToShow = _.cloneDeep(item.processes)
                        if (this.processToShow) {
                            item.processesToShow = item.processesToShow.filter(
                                p => p == this.processToShow.id
                            )
                        }

                        item.processesToShow.forEach(process => {
                            let processInfo = wo.processesInfo.find(
                                info => info.process.id == process
                            )

                            if (!processInfo) {
                                let totalDelivered = item.deliveries
                                    ? item.deliveries.reduce(
                                          (total, register) =>
                                              register.prevProcess.id == process
                                                  ? total + register.qty
                                                  : total,
                                          0
                                      )
                                    : 0
                                let totalProduced = item.production
                                    ? item.production.reduce(
                                          (total, register) =>
                                              register.process.id == process
                                                  ? total + register.qty
                                                  : total,
                                          0
                                      )
                                    : 0

                                let received = item.deliveries
                                    ? item.deliveries.filter(
                                          register =>
                                              register.nextProcess.id == process
                                      )
                                    : []
                                let totalReceived = received.reduce(
                                    (total, register) => total + register.qty,
                                    0
                                )
                                let processIndex = this.settingProcesses.processes.findIndex(
                                    p => p.id == process
                                )

                                const pIndex = item.processes.findIndex(
                                    p => p == process
                                )
                                if (pIndex == 0) {
                                    totalReceived = item.quantity
                                }
                                wo.processesInfo.push({
                                    process: {
                                        id: this.settingProcesses.processes[
                                            processIndex
                                        ].id,
                                        name: this.settingProcesses.processes[
                                            processIndex
                                        ].name,
                                    },
                                    qty: item.quantity,
                                    delivered: totalDelivered,
                                    produced: totalProduced,
                                    received: totalReceived,
                                    available: totalReceived - totalProduced,
                                })
                            } else {
                                processInfo.qty += item.quantity
                                processInfo.delivered += item.deliveries
                                    ? item.deliveries.reduce(
                                          (total, register) =>
                                              register.prevProcess.id == process
                                                  ? total + register.qty
                                                  : total,
                                          0
                                      )
                                    : 0
                                processInfo.produced += item.production
                                    ? item.production.reduce(
                                          (total, register) =>
                                              register.process.id == process
                                                  ? total + register.qty
                                                  : total,
                                          0
                                      )
                                    : 0

                                const receivedEntries = item.deliveries
                                    ? item.deliveries.filter(
                                          e => e.nextProcess == process
                                      )
                                    : []

                                processInfo.received += receivedEntries.reduce(
                                    (total, register) => total + register.qty,
                                    0
                                )
                            }
                        })

                        //ready for packing process
                        let totalProduced = item.production
                            ? item.production.reduce(
                                  (total, register) =>
                                      register.process.id ==
                                      this.readyForPackingProcess.id
                                          ? total + register.qty
                                          : total,
                                  0
                              )
                            : 0

                        packingProcess.qty += item.quantity
                        packingProcess.delivered = 'NA'
                        packingProcess.produced += item.packedQty
                        packingProcess.available =
                            totalProduced - (packingProcess.produced || 0)

                        //quality process
                        qualityProcess.qty += item.quantity
                        qualityProcess.delivered = 'NA'
                        qualityProcess.produced =
                            this.calculateRejectedQty(item) +
                            this.calculateReleasedQty(item)
                        qualityProcess.available = this.calculateQualityPending(
                            item
                        )
                    }
                })
                if (showDefaultProcesses) {
                    wo.processesInfo.push(qualityProcess)
                    wo.processesInfo.push(packingProcess)
                }
            }

            if (this.selectedOrder && this.selectedOrder.id == wo.id) {
                this.selectedOrder = _.cloneDeep(wo)
            }
        },
        async trackWO(wo) {
            try {
                this.loading = true
                let query = firestore
                    .collection('companies')
                    .doc(this.companyId)
                    .collection('workOrders')
                    .doc(wo.id)

                this.listener = await query.onSnapshot(async docSnapshot => {
                    if (docSnapshot.exists) {
                        const workOrder = Object.assign(docSnapshot.data(), {
                            id: docSnapshot.id,
                        })

                        const userIndex = this.users.findIndex(
                            u => u.id == workOrder.createdBy
                        )
                        if (userIndex >= 0) {
                            workOrder.createdBy = this.users[userIndex]
                        } else {
                            workOrder.createdBy = { id: workOrder.createdBy }
                            workOrder.createdBy.name = ''
                        }

                        const projectIndex = this.projects.findIndex(
                            p => p.id == workOrder.projectId
                        )
                        if (projectIndex >= 0) {
                            workOrder.project = this.projects[projectIndex]
                        } else {
                            workOrder.project = await API.getProject(
                                workOrder.projectId
                            )
                            this.projects.push(workOrder.project)
                        }
                        if (this.showProcesses) {
                            this.setProcessesInfo(workOrder)
                        }
                        this.selectedOrder = _.cloneDeep(workOrder)
                    } else {
                        const projectIndex = this.projects.findIndex(
                            p => p.id == wo.projectId
                        )
                        if (projectIndex >= 0) {
                            wo.project = this.projects[projectIndex]
                        } else {
                            wo.project = await API.getProject(wo.projectId)
                            this.projects.push(wo.project)
                        }
                        wo.projectManager = this.users.find(
                            user => user.id == wo.project.projectManager
                        )
                        wo.creator = this.users.find(
                            user => user.id == wo.createdBy
                        )
                        wo.approvedBy = this.users.find(
                            user => user.id == wo.approvedBy
                        )
                        if (this.showProcesses) {
                            this.setProcessesInfo(wo)
                        }
                        this.selectedOrder = _.cloneDeep(wo)
                    }
                    this.openProcessWorkOrder()
                })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async trackBOM(bom) {
            try {
                this.loading = true
                let query = firestore
                    .collection('companies')
                    .doc(this.companyId)
                    .collection('workOrders')
                    .doc(bom.workOrderId)
                    .collection('BOMs')
                    .doc(bom.id)

                this.listener = await query.onSnapshot(async docSnapshot => {
                    if (docSnapshot.exists) {
                        let bomDB = Object.assign(docSnapshot.data(), {
                            id: docSnapshot.id,
                        })
                        bomDB.project = await API.getProject(bomDB.projectId)
                        bomDB.projectManager = this.users.find(
                            user => user.id == bomDB.project.projectManager
                        )
                        bomDB.creator = this.users.find(
                            user => user.id == bomDB.createdBy
                        )
                        bomDB.approvedBy = this.users.find(
                            user => user.id == bomDB.approvedBy
                        )
                        this.selectedBOM = _.cloneDeep(bomDB)
                    } else {
                        bom.project = await API.getProject(bom.projectId)
                        bom.projectManager = this.users.find(
                            user => user.id == bom.project.projectManager
                        )
                        bom.creator = this.users.find(
                            user => user.id == bom.createdBy
                        )
                        bom.approvedBy = this.users.find(
                            user => user.id == bom.approvedBy
                        )
                        this.selectedBOM = _.cloneDeep(bom)
                    }
                    this.openProcessBOM()
                })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        calculateQualityPending(item) {
            try {
                let pendingQty = 0
                const lastProductionProcess =
                    item.processes[item.processes.length - 1]
                if (item.production) {
                    const production = item.production.filter(
                        entry => entry.process.id == lastProductionProcess
                    )
                    if (production.length > 0) {
                        pendingQty = production.reduce(
                            (accumulator, entry) => accumulator + entry.qty,
                            0
                        )
                    }
                }
                if (item.quality) {
                    const rejected = item.quality.reduce(
                        (accumulator, entry) =>
                            accumulator + (entry.rejectQty || 0),
                        0
                    )
                    const released = item.quality.reduce(
                        (accumulator, entry) =>
                            accumulator + (entry.releaseQty || 0),
                        0
                    )
                    pendingQty -= Number(released || 0) + Number(rejected || 0)
                }
                return pendingQty
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        calculateReleasedQty(item) {
            try {
                let released = 0
                if (item.quality) {
                    const value = item.quality.reduce(
                        (accumulator, entry) =>
                            accumulator + (entry.releaseQty || 0),
                        0
                    )
                    released += Number(value || 0)
                }
                return released
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        calculateRejectedQty(item) {
            try {
                let rejected = 0
                if (item.quality) {
                    const value = item.quality.reduce(
                        (accumulator, entry) =>
                            accumulator + (entry.rejectQty || 0),
                        0
                    )
                    rejected += Number(value || 0)
                }
                return rejected
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        partialsAlert() {
            this.activateAlert = true
            this.alertMessage =
                'Partial quantities have been recorded correctly.'
        },
        openProcessBOM() {
            if (!this.processBOM) this.processBOM = true
        },
        closeProcessBOM() {
            this.listener()
            this.listener = null
            this.selectedBOM = {}
            this.processBOM = false
        },
        replaceItems() {},
    },
}
</script>

<style>
.v-input .v-label {
    font-size: 16px;
    margin-left: 5px;
}

.v-text-field input {
    padding-bottom: 25px;
    margin-left: 5px;
    font-size: 18px;
}
</style>
